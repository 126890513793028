import { 
  GoogleAuthProvider, 
  FacebookAuthProvider, 
  signInWithPopup, 
  signInWithEmailAndPassword 
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebase"; // Ensure 'db' is your Firestore instance


// Function to store user data in Firestore
const storeUserData = async (user: any) => {
  const userRef = doc(db, "users", user.uid); // Reference to 'users' collection with user ID as the document ID
  const userDoc = await getDoc(userRef);

  // If user document doesn't exist, create it
  if (!userDoc.exists()) {
    const userData = {
      id: user.uid,
      name: user.displayName || "Anonymous",
      email: user.email,
      profilePicture: user.photoURL || null,
      provider: user.providerData[0]?.providerId || "email",
      createdAt: new Date(),
      isActive: true,
      addresses: [],
      wishlist: [],
      cart: [],
    };

    await setDoc(userRef, userData);
  }
};

// Google Sign-In
export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    await storeUserData(user); // Store user data in Firestore
    return user; // User details
  } catch (error) {
    console.error("Google Sign-In Error:", error);
    throw error;
  }
};

// Facebook Sign-In
export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    await storeUserData(user); // Store user data in Firestore
    return user; // User details
  } catch (error) {
    console.error("Facebook Sign-In Error:", error);
    throw error;
  }
};

// Email/Password Authentication for Existing Users
export const loginUser = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await storeUserData(user); // Store or update user data in Firestore
    return user;
  } catch (error) {
    console.error("Login Error:", error);
    throw error;
  }
};
