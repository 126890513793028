import React from "react";
import { FaHeart } from "react-icons/fa";
import { Product } from "../../types/Product";
import { Link } from "react-router-dom";


const ProductItem = ({ product }: { product: Product }) => {
  const { id, name, pricing, inventory, media, shortDescription } = product;
  

  return (
    <div className="flex flex-col mx-auto max-w-sm overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md hover:scale-105 ease-in-out transition-all duration-300">
              <Link to={`/products/${id}`}>
      <Link
        className="relative overflow-hidden"
        to={`/products/${id}`} // Link to product detail page
      >
        <img
          className="object-cover w-screen h-48 md:h-56 lg:h-52"
          src={media.images[0]} // Display the first image
          alt={name}
        />
        {pricing.discountPrice < pricing.price && (
          <span className="absolute top-2 left-2 rounded-full bg-black px-2 py-1 text-xs md:text-sm font-medium text-white">
            {Math.round(
              ((pricing.price - pricing.discountPrice) / pricing.price) * 100
            )}
            % OFF
          </span>
        )}
        <span className="absolute top-2 right-2 text-gray-300">
          <FaHeart size={16} />
        </span>
      </Link>
      <div className="mt-1 px-4 pb-4">
        <Link to={`/products/${id}`}>
          <h5 className="text-sm md:text-base lg:text-base font-normal tracking-tight text-slate-900">
            {name}
          </h5>
        </Link>
        <p className="text-xs md:text-sm text-slate-500">{shortDescription}</p>
        <div className="flex items-center justify-between mt-2">
          <p className="text-sm md:text-lg lg:text-xl">
            <span className="font-black text-slate-900">
              ₹{pricing.discountPrice}
            </span>
            {pricing.discountPrice < pricing.price && (
              <span className="text-xs md:text-sm text-slate-500 line-through ml-2">
                ₹{pricing.price}
              </span>
            )}
          </p>
          <span className="text-xs md:text-sm text-slate-500">
            {inventory.stock > 0 ? "In Stock" : "Out of Stock"}
          </span>
        </div>
        {/* Uncomment this if you need an Add to Cart button */}
        {/* <button
          onClick={handleAddToCart}
          className="flex items-center justify-center rounded-md bg-slate-900 px-4 py-2 text-center text-xs md:text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
        >
          <FaShoppingCart className="mr-2 text-sm md:text-lg" />
          Add to cart
        </button> */}
      </div>
      </Link>
    </div>
  );
};

export default ProductItem;
