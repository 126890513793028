// UploadProducts.tsx
import React, { useState } from "react";
import { db, collection, setDoc, doc, writeBatch } from "../../services/firebase";
import { Product } from "../../types/Product"; // Your Product type if available

const productsData: Product[] = [
    {
        id: "1",
        name: "Ultrasonic Essential Oil Diffuser",
        slug: "ultrasonic-essential-oil-diffuser-arimo-certified",
        description: "An ultrasonic essential oil diffuser with premium Arimo certification.",
        shortDescription: "Premium essential oil diffuser with ultrasonic technology.",
        category: ["Home Fragrance", "Aroma Diffusers"],
        tags: ["Essential Oil", "Ultrasonic", "Certified"],
        pricing: {
          price: 1499,
          discountPrice: 1499,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 1200,
          availability: true,
          sku: "ADF-001",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/He1b2eb8265e54e638983848b24dfdf8bm.jpg_720x720q50.jpg",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: true,
        },
      },
      {
        id: "2",
        name: "Ceramic Aroma Diffuser - White",
        slug: "ceramic-aroma-diffuser-white-arimo-certified",
        description: "A sleek ceramic aroma diffuser in white with Arimo certification.",
        shortDescription: "Elegant white ceramic diffuser for aromatherapy.",
        category: ["Home Fragrance", "Aroma Diffusers"],
        tags: ["Ceramic", "White", "Certified"],
        pricing: {
          price: 899,
          discountPrice: 899,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 900,
          availability: true,
          sku: "ADF-002",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/H965bfd5c53654e0e9242e96bc19423aeo.jpg_720x720q50.jpg",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: true,
        },
      },
      {
        id: "3",
        name: "Portable USB Aroma Diffuser",
        slug: "portable-usb-aroma-diffuser-arimo-certified",
        description: "A portable USB-powered aroma diffuser with Arimo certification.",
        shortDescription: "Compact and portable diffuser for on-the-go use.",
        category: ["Home Fragrance", "Portable Diffusers"],
        tags: ["USB", "Portable", "Certified"],
        pricing: {
          price: 749,
          discountPrice: 749,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 1500,
          availability: true,
          sku: "ADF-003",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/Hfc6f5f50347749d4a218f146c73bf3bbJ.jpg_720x720q50.jpg",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: false,
        },
      },
      {
        id: "4",
        name: "Wood Grain Ultrasonic Diffuser",
        slug: "wood-grain-ultrasonic-diffuser-arimo-certified",
        description: "A stylish wood grain ultrasonic diffuser with Arimo certification.",
        shortDescription: "Natural wood-grain finish diffuser with ultrasonic technology.",
        category: ["Home Fragrance", "Aroma Diffusers"],
        tags: ["Wood Grain", "Ultrasonic", "Certified"],
        pricing: {
          price: 1299,
          discountPrice: 1299,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 2000,
          availability: true,
          sku: "ADF-004",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/Hae476b11c61446108c7c590735c53c71T.jpg_720x720q50.jpg",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: true,
        },
      },
      {
        id: "5",
        name: "Mini Portable Aroma Diffuser",
        slug: "mini-portable-aroma-diffuser-arimo-certified",
        description: "A mini portable aroma diffuser perfect for small spaces.",
        shortDescription: "Compact and lightweight diffuser for small areas.",
        category: ["Home Fragrance", "Portable Diffusers"],
        tags: ["Mini", "Portable", "Certified"],
        pricing: {
          price: 599,
          discountPrice: 599,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 1000,
          availability: true,
          sku: "ADF-005",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/H5f2c41bd258e448294f68cf337764cbcB.png?avif=close",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: false,
        },
      },
      {
        id: "6",
        name: "Smart WiFi Aroma Diffuser",
        slug: "smart-wifi-aroma-diffuser-arimo-certified",
        description: "A smart WiFi-enabled aroma diffuser with remote control.",
        shortDescription: "WiFi-enabled diffuser with smart features.",
        category: ["Home Fragrance", "Smart Diffusers"],
        tags: ["Smart", "WiFi", "Certified"],
        pricing: {
          price: 2499,
          discountPrice: 2499,
          currency: "INR",
          tax: { type: "GST", rate: 18 },
        },
        inventory: {
          stock: 700,
          availability: true,
          sku: "ADF-006",
        },
        media: {
          images: [
            "https://s.alicdn.com/@sc04/kf/H660c289b27c64dbb9c80c37d682427623.png?avif=close",
          ],
        },
        meta: {
          createdAt: "2024-12-01T12:00:00Z",
          updatedAt: "2024-12-01T12:00:00Z",
          isFeatured: true,
        },
      },
];

const UploadProducts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadProducts = async () => {
    try {
      setLoading(true);
      setError(null);

      const batch = writeBatch(db); // Create a batch for atomic writes

      productsData.forEach((product) => {
        const productRef = doc(collection(db, "products"), product.id); // Reference to Firestore
        batch.set(productRef, product); // Add the product to the batch
      });

      // Commit the batch (write all at once)
      await batch.commit();

      setLoading(false);
      alert("Products uploaded successfully!");
    } catch (err) {
      setLoading(false);
      setError("Failed to upload products. Please try again.");
      console.error(err);
    }
  };

  return (
    <div className="container px-8 py-6 mx-auto">
      <h2 className="text-xl font-semibold mb-4">Upload Products</h2>
      {error && <p className="text-red-500">{error}</p>}
      <button
        onClick={uploadProducts}
        disabled={loading}
        className={`${
          loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-700"
        } text-white py-2 px-4 rounded-md`}
      >
        {loading ? "Uploading..." : "Upload Products"}
      </button>
    </div>
  );
};

export default UploadProducts;
