import React from 'react';
import { FaRocket, FaShieldAlt, FaHandsHelping, FaCogs } from 'react-icons/fa';

const WhyUs = () => {
  const features = [
    {
      icon: <FaRocket size={40} className="text-blue-500" />,
      title: 'Fast & Reliable',
      description: 'Experience unmatched speed and reliability with our services.',
    },
    {
      icon: <FaShieldAlt size={40} className="text-green-500" />,
      title: 'Secure & Trusted',
      description: 'Your security and trust are our top priorities.',
    },
    {
      icon: <FaHandsHelping size={40} className="text-yellow-500" />,
      title: 'Customer Support',
      description: 'We are here to help you 24/7, whenever you need us.',
    },
    {
      icon: <FaCogs size={40} className="text-purple-500" />,
      title: 'Innovative Solutions',
      description: 'Providing cutting-edge solutions tailored to your needs.',
    },
  ];

  return (
    <section className="py-16 px-6 bg-gray-50  cursor-default hover:cursor-default space-y-6 lg:space-y-0 lg:space-x-16 p-6">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Why Choose Us?</h2>
        <p className="text-gray-600 mb-12">
          We provide exceptional services to help you achieve your goals efficiently and effectively.
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="flex justify-center mb-4">{feature.icon}</div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
