import { v4 as uuidv4 } from 'uuid';

const getOrCreateUserId = (): string => {
  // Check if a user ID already exists in local storage
  let userId = localStorage.getItem('userId');
  
  if (!userId) {
    // If not, generate a new one and save it to local storage
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }

  return userId;
};

export default getOrCreateUserId;



  