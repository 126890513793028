import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProducts } from "../../context/ProductContext";
import { useAuth } from "../../context/AuthContext";
import WhyUs from "../cards/WhyUs";
import defaultImage from "../../assets/images/p1.avif";
import { Product } from "../../types/Product";
import { addItemToCart, syncCartWithFirestore } from "../../services/cartService";
import { CartItem } from "../../types/CartItem";
import { convertProductToCartItem } from "../../services/cartService"; // Import the conversion utility
import getOrCreateUserId from "../../utils/generateUserId";

const ProductOverview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { products, loading } = useProducts();
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedSize, setSelectedSize] = useState<string | undefined>("default");
  const [selectedColor, setSelectedColor] = useState<string | undefined>("default");
  const { user } = useAuth();
  const quantity = 1;

  const userId = user ? user.uid : getOrCreateUserId();


  useEffect(() => {
    if (id && products.length > 0) {
      const foundProduct = products.find((p) => p.id === id);
      if (foundProduct) {
        setProduct(foundProduct);
        setSelectedImage(foundProduct.media.images[0]);
      }
    }
  }, [id, products]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  const { media, pricing, attributes, name, description } = product;
  const { images } = media;
  const { price, discountPrice, currency } = pricing;
  const { color, size } = attributes || {};

  const handleAddToCart = async () => {
    if (!userId) {
      alert("Please log in to add items to the cart.");
      return;
    }

    // if (!selectedSize || !selectedColor || selectedSize === "default" || selectedColor === "default") {
    //   alert("Please select a size and color.");
    //   return;
    // }

    const cartItem: CartItem = convertProductToCartItem(product, selectedSize, selectedColor, quantity);
    try {
      await addItemToCart(userId, cartItem);
      // alert("Product added to cart successfully!");
    } catch (error) {
      console.error("Error adding to cart: ", error);
      alert("Failed to add product to cart. Please try again.");
    }
  };

  const handleBuyNow = () => {
    if (!selectedSize || !selectedColor || selectedSize === "default" || selectedColor === "default") {
      alert("Please select a size and color.");
      return;
    }
    console.log(
      `Buying now: ${product.name}, Size: ${selectedSize}, Color: ${selectedColor}, Quantity: ${quantity}`
    );
    // Redirect to the checkout page (needs implementation)
    navigate("/checkout");
  };

  return (
    <main className="bg-neutral-50">
      <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-16 p-6">
        {/* Left: Product Images */}
        <div className="flex flex-col md:flex-row-reverse lg:flex-row-reverse items-center lg:items-start">
          <img
            src={selectedImage || defaultImage}
            alt="Selected Product"
            className="w-80 h-80 object-cover rounded-md shadow-md md:ml-8"
          />
          <div className="flex md:flex-col lg:flex-col mt-4 max-w-96 overflow-hidden overflow-x-scroll">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className={`w-16 h-16 object-cover rounded-md cursor-pointer border-2 ${
                  selectedImage === image ? "border-gray-700" : "border-gray-200"
                }`}
                onClick={() => setSelectedImage(image)}
              />
            ))}
          </div>
        </div>

        {/* Right: Product Details */}
        <div className="flex flex-col space-y-4">
          <h1 className="max-w-96 text-xl font-normal">{name}</h1>
          <p className="text-gray-600">{description}</p>
          <p className="text-xl font-normal text-gray-600">
            {currency} {discountPrice ? discountPrice.toFixed(2) : price.toFixed(2)}
          </p>

          {/* Size Selector */}
          {size && (
            <div>
              <h3 className="text-sm font-medium mb-2">Select Size:</h3>
              <div className="flex space-x-2">
                {size.map((s, index) => (
                  <button
                    key={index}
                    className={`px-4 py-2 rounded-md text-sm font-medium border-2 ${
                      selectedSize === s ? "bg-gray-950 text-white" : "bg-white text-gray-700"
                    }`}
                    onClick={() => setSelectedSize(s)}
                  >
                    {s}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Color Selector */}
          {color && (
            <div>
              <h3 className="text-sm font-medium mb-2">Select Color:</h3>
              <div className="flex space-x-2">
                {color.map((c, index) => (
                  <button
                    key={index}
                    style={{ backgroundColor: c }}
                    className={`w-8 h-8 rounded-full border-2 ${
                      selectedColor === c ? "border-blue-500" : "border-gray-300"
                    }`}
                    title={c}
                    onClick={() => setSelectedColor(c)}
                  ></button>
                ))}
              </div>
            </div>
          )}

          {/* Add to Cart and Buy Now Buttons */}
          <div className="grid grid-cols-2 gap-2 w-full">
            <button
              className="py-3 bg-orange-500 text-white font-medium rounded-md hover:bg-orange-600 transition"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>
            <button
              className="py-3 bg-orange-500 text-white font-medium rounded-md hover:bg-orange-600 transition"
              onClick={handleBuyNow}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>

      {/* Why Us Section */}
      <WhyUs />
    </main>
  );
};

export default ProductOverview;
