import React, { createContext, useContext, useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { Product } from "../types/Product";
import { db } from "../services/firebase";

interface ProductContextProps {
  products: Product[];
  loading: boolean;
  fetchProducts: () => void;
}

interface ProductProviderProps {
  children: React.ReactNode; // Explicitly define the type of children
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined);

export const useProducts = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Function to fetch products and cache them in localStorage
  const fetchProducts = async () => {
    const cachedProducts = localStorage.getItem("products");

    if (cachedProducts) {
      // If cached, use localStorage data
      setProducts(JSON.parse(cachedProducts));
      setLoading(false);
    } else {
      // Otherwise, fetch from Firebase
      const querySnapshot = await getDocs(collection(db, "products"));
      const fetchedProducts: Product[] = [];
      querySnapshot.forEach((doc) => {
        fetchedProducts.push({ id: doc.id, ...doc.data() } as Product);
      });

      // Cache in localStorage for subsequent visits
      localStorage.setItem("products", JSON.stringify(fetchedProducts));

      // Set products in state
      setProducts(fetchedProducts);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []); // Fetch only once when the app loads

  return (
    <ProductContext.Provider value={{ products, loading, fetchProducts }}>
      {children}
    </ProductContext.Provider>
  );
};
