interface RazorpayOptions {
    key: string; // Razorpay Key ID
    amount: number; // Amount in paise
    currency: string; // Currency (e.g., 'INR')
    name: string; // Business Name
    description: string; // Payment Description
    image?: string; // Logo URL
    order_id?: string; // Optional Order ID (if manually created)
    prefill?: {
      name?: string;
      email?: string;
      contact?: string;
    };
    notes?: Record<string, string>;
    theme?: {
      color?: string;
    };
  }
  
  export const initializeRazorpay = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (document.getElementById("razorpay-sdk")) {
        resolve(true);
        return;
      }
  
      const script = document.createElement("script");
      script.id = "razorpay-sdk";
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };
  
  export const openRazorpayPayment = (
    options: RazorpayOptions,
    onSuccess: (response: any) => void,
    onFailure: (error: any) => void
  ) => {
    const initialize = async () => {
      const isScriptLoaded = await initializeRazorpay();
      if (!isScriptLoaded) {
        alert("Failed to load Razorpay SDK. Please check your internet connection.");
        return;
      }
  
      const rzp = new (window as any).Razorpay({
        key: options.key,
        amount: options.amount,
        currency: options.currency,
        name: options.name,
        description: options.description,
        image: options.image,
        order_id: options.order_id,
        prefill: options.prefill,
        notes: options.notes,
        theme: options.theme,
        handler: (response: any) => {
          onSuccess(response);
        },
        modal: {
          ondismiss: () => {
            console.log("Payment popup closed by user.");
          },
        },
      });
  
      rzp.on("payment.failed", (response: any) => {
        onFailure(response.error);
      });
  
      rzp.open();
    };
  
    initialize();
  };
  