import React, { useEffect, useState } from "react";
import { aromaDiffusers } from "../../mockData/productsMock"; // Default mock data
import ProductList from "./ProductList";
import { Product } from "../../types/Product";
import { useProducts } from "../../context/ProductContext";

const Products = () => {

  const{ products}=useProducts();
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products); // Filtered products state
  const [sortOption, setSortOption] = useState<string>("relevance"); // Sorting state
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false); // Dropdown visibility state

  // Handle Sorting & Filtering
  useEffect(() => {
    let sortedProducts = [...aromaDiffusers]; // Use mock data directly

    switch (sortOption) {
      case "price-low-to-high":
        sortedProducts.sort(
          (a, b) => a.pricing.discountPrice - b.pricing.discountPrice
        );
        break;
      case "price-high-to-low":
        sortedProducts.sort(
          (a, b) => b.pricing.discountPrice - a.pricing.discountPrice
        );
        break;
      case "sale":
        sortedProducts = sortedProducts.filter(
          (product) => product.pricing.discountPrice < product.pricing.price
        );
        break;
      default:
        sortedProducts = [...aromaDiffusers]; // Default: relevance
    }

    setFilteredProducts(sortedProducts);
  }, [sortOption]);

  const sortOptions = [
    { value: "relevance", label: "Relevance" },
    { value: "price-low-to-high", label: "Price: Low to High" },
    { value: "price-high-to-low", label: "Price: High to Low" },
    { value: "sale", label: "On Sale" },
  ];

  return (
    <main>
      {/* Filter & Sort Options */}
      <div className="  sm:px-8 md:px-16 lg:px-28 my-8 flex flex-col sm:flex-row justify-between items-center">
        <h2 className="text-xl font-semibold mb-4 sm:mb-0">All Products</h2>
        <div className="relative">
          {/* Dropdown Toggle */}
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="px-4 py-2 bg-stone-200 text-black rounded-md shadow-sm font-semibold hover:bg-stone-300 focus:outline-none"
          >
            {sortOptions.find((opt) => opt.value === sortOption)?.label}
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-stone-200 rounded-md shadow-lg z-10">
              {sortOptions.map((option) => (
                <button
                  key={option.value}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    sortOption === option.value
                      ? "bg-stone-400 text-white"
                      : "hover:bg-stone-300"
                  }`}
                  onClick={() => {
                    setSortOption(option.value);
                    setIsDropdownOpen(false); // Close the dropdown
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Pass Filtered Products to ProductList */}
      <ProductList products={filteredProducts} userId={"guest"} />
    </main>
  );
};

export default Products;
