import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/header/Navbar';
import Footer from './components/footer/Footer';
import { ModalProvider } from './context/ModalContext';
import { AuthProvider } from './context/AuthContext';
import { ProductProvider } from './context/ProductContext';
import Home from './pages/Home';
import ProductsPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import Checkout from './pages/Checkout';
import ProductOverview from './components/products/ProductOverview';
import AddProductForm from './components/admin/AddProductForm';
import UploadProducts from './components/admin/UploadData';
import { CartProvider } from './context/CartContext';
import getOrCreateUserId from './utils/generateUserId';

const App: React.FC = () => {

  const userId = getOrCreateUserId();
  
  return (
   <main>
     <ProductProvider>
      <AuthProvider>
        <ModalProvider>
         <CartProvider userId={userId}>
         <BrowserRouter>
            <Navbar />

            {/* main-content */}
           <div className='pt-16'>
           <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/upload-data" element={<UploadProducts />} />
              <Route path="/products" element={<ProductsPage />} />
              {/* Pass userId as prop to CartPage */}
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/add-product" element={<AddProductForm />} />
              <Route path="/products/:id" element={<ProductOverview />} />
            </Routes>
          <Footer />
           </div>
          </BrowserRouter>
         </CartProvider>
        </ModalProvider>
      </AuthProvider>
    </ProductProvider>
   </main>
  );
};


export default App;
