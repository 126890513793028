import { createContext, useContext, useState, useEffect, ReactNode, useMemo } from "react";
import {
  addItemToCart,
  updateCartItemQuantity,
  removeCartItem,
  clearCart,
  syncCartWithFirestore,
  listenToCartCount,
  getTotalPrice,
} from "../services/cartService";
import { CartItem } from "../types/CartItem";

interface CartContextProps {
  cart: CartItem[];
  cartCount: number;
  totalPrice: number;
  addToCart: (item: CartItem) => void;
  updateQuantity: (itemId: string, quantity: number) => void;
  removeFromCart: (itemId: string) => void;
  clearUserCart: () => void;
}

const CartContext = createContext<CartContextProps | null>(null);

export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

interface CartProviderProps {
  children: ReactNode;
  userId: string | null;
}

export const CartProvider = ({ children, userId }: CartProviderProps) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [cartCount, setCartCount] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // Sync cart data with Firestore when userId changes
  useEffect(() => {
    if (userId) {
      syncCartWithFirestore(userId).then(setCart);

      const unsubscribe = listenToCartCount(userId, setCartCount);

      return () => {
        unsubscribe();
      };
    }
  }, [userId]);

  // Calculate total price whenever the cart updates
  useEffect(() => {
    if (userId) {
      getTotalPrice(userId).then(setTotalPrice).catch(console.error);
    }
  }, [cart, userId]);

  const addToCart = (item: CartItem) => {
    if (!userId) {
      console.error("User ID is required to add to cart.");
      return;
    }
    addItemToCart(userId, item).then(() => syncCartWithFirestore(userId).then(setCart));
  };

  const updateQuantity = (itemId: string, quantity: number) => {
    if (!userId) {
      console.error("User ID is required to update cart.");
      return;
    }
    updateCartItemQuantity(userId, itemId, quantity).then(() => syncCartWithFirestore(userId).then(setCart));
  };

  const removeFromCart = (itemId: string) => {
    if (!userId) {
      console.error("User ID is required to remove from cart.");
      return;
    }
    removeCartItem(userId, itemId).then(() => syncCartWithFirestore(userId).then(setCart));
  };

  const clearUserCart = () => {
    if (!userId) {
      console.error("User ID is required to clear cart.");
      return;
    }
    clearCart(userId).then(() => setCart([]));
  };

  // Memoize the context value to avoid unnecessary rerenders
  const value = useMemo(() => ({
    cart,
    cartCount,
    totalPrice,
    addToCart,
    updateQuantity,
    removeFromCart,
    clearUserCart,
  }), [cart, cartCount, totalPrice]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
