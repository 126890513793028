// src/components/Login.tsx
import React, { useState } from "react";
import { AuthButtons } from "../auth/AuthButton";
import { useModal } from "../../context/ModalContext";
import { IoMdClose } from "react-icons/io";
import { useAuth } from "../../context/AuthContext";  // Assuming this context is responsible for handling Firebase authentication
import { signInWithEmailAndPassword } from "firebase/auth";  // Firebase authentication import
import { auth } from "../../services/firebase";  // Firebase config (ensure you have this set up correctly)

const Login = () => {
  const { closeModal } = useModal();
  // const { loginUser } = useAuth();  // Assuming this is a function from AuthContext to handle login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Firebase login method
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Logged in successfully");
      closeModal();  // Close the modal on successful login
    } catch (error: any) {
      console.error("Login failed:", error.message);
      setError("Failed to login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-20">
      <div className="flex h-full items-center justify-center">
        <div className="rounded-lg border border-gray-200 bg-white shadow-md flex-col flex h-full items-center justify-center sm:px-4">
          <div className="flex h-full flex-col justify-center gap-4 p-6">
            <div className="left-0 right-0 inline-block border-gray-200 px-2 py-2.5 sm:px-4">
              <form className="flex flex-col gap-4 pb-4" onSubmit={handleLogin}>
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-2xl font-normal">Login</h1>
                  <IoMdClose size={20} onClick={closeModal} className="cursor-pointer" />
                </div>
                <div>
                  <div className="mb-2">
                    <label className="text-sm font-medium text-gray-900" htmlFor="email">
                      Email:
                    </label>
                  </div>
                  <div className="flex w-full rounded-lg pt-1">
                    <div className="relative w-full">
                      <input
                        className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="email@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-2">
                    <label className="text-sm font-medium text-gray-900" htmlFor="password">
                      Password
                    </label>
                  </div>
                  <div className="flex w-full rounded-lg pt-1">
                    <div className="relative w-full">
                      <input
                        className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                        id="password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <p className="mt-2 cursor-pointer text-blue-500 hover:text-blue-600">Forgot password?</p>
                </div>
                <div className="flex flex-col gap-2">
                  <button
                    type="submit"
                    className="border transition-colors focus:ring-2 p-0.5 disabled:cursor-not-allowed border-transparent bg-sky-600 hover:bg-sky-700 active:bg-sky-800 text-white disabled:bg-gray-300 disabled:text-gray-700 rounded-lg"
                    disabled={loading}
                  >
                    <span className="flex items-center justify-center gap-1 font-medium py-1 px-2.5 text-base">
                      {loading ? "Logging in..." : "Login"}
                    </span>
                  </button>
                  {/* Auth Buttons */}
                  <AuthButtons />
                </div>
              </form>
              <div className="min-w-[270px]">
                <div className="mt-4 text-center">
                  New user?{" "}
                  <a className="text-blue-500 underline hover:text-blue-600" href="/signup">
                    Create account here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
