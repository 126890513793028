import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { syncCartWithFirestore } from '../services/cartService';
import { useAuth } from '../context/AuthContext';
import { CartItem } from '../types/CartItem';
import { openRazorpayPayment } from '../services/razorpayService';
import arimoLogo from '../assets/images/logo/stone900.png'
const Checkout = () => {
  const { user } = useAuth();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const handlePayNow = async () => {
    try {
      const amount = 10 * 100; // Amount in paise (e.g., ₹500)

      openRazorpayPayment(
        {
          key: "rzp_live_bWTSUva9uEpjBN", // Replace with your Razorpay Key ID
          amount, // Amount in paise
          currency: "INR", // Currency
          name: "ArimoIndia",
          description: "Order Payment",
          image: arimoLogo, // Optional: Replace with your logo URL
          prefill: {
            name: "Srijan Lal",
            email: "srijanlal707@gmail.com",
            contact: "8864066463",
          },
          theme: { color: "#3399cc" },
        },
        (response) => {
          console.log("Payment Successful!", response);
          alert("Payment Successful!");
        },
        (error) => {
          console.error("Payment Failed", error);
          alert("Payment Failed. Please try again.");
        }
      );
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Payment initiation failed. Please try again.");
    }
  };

  const orderId = "order_9A33XWu170gUtm"; // Replace with your generated order ID
  const totalPrice = 10; // Replace with the total price to pay

 
  useEffect(() => {
    const fetchCart = async () => {
      if (!user) {
        console.log("User not logged in. Skipping cart fetch.");
        setLoading(false);
        return;
      }

      try {
        console.log("Syncing cart with Firestore...");
        // Sync the local cart with Firestore when the user is logged in
        const syncedCart = await syncCartWithFirestore(user.uid);
        console.log("Fetched synced cart items:", syncedCart);
        setCartItems(syncedCart);
      } catch (error) {
        console.error("Error fetching cart items: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, [user]);

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <section className="bg-white py-8 antialiased md:py-16">
      <form action="#" className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <ol className="items-center flex w-full max-w-2xl text-center text-sm font-medium text-gray-500  sm:text-base">
          <Link to="/cart" className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
            <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
              <svg className="me-2 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              Cart
            </span>
          </Link>

          <Link to="/checkout" className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
            <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
              <svg className="me-2 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              Checkout
            </span>
          </Link>

          <Link to="/order-summary" className="flex shrink-0 items-center cursor-pointer">
            <svg className="me-2 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            Order summary
          </Link>
        </ol>

        <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
          <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-900 ">Delivery Details</h2>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label htmlFor="your_name" className="mb-2 block text-sm font-medium text-gray-900 "> Your name </label>
                  <input type="text" id="your_name" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 " placeholder="Bonnie Green" required />
                </div>

               

                <div>
                  <label htmlFor="phone-input-3" className="mb-2 block text-sm font-medium text-gray-900 "> Phone Number* </label>
                  <div className="flex items-center">
                    <button id="dropdown-phone-button-3" data-dropdown-toggle="dropdown-phone-3" className="z-10 inline-flex shrink-0 items-center rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100 " type="button">
                     
                      +91
                      <svg className="-me-0.5 ms-2 h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                      </svg>
                    </button>
                      
                    <div className="relative w-full">
                      <input type="text" id="phone-input" className="z-20 block w-full rounded-e-lg border border-s-0 border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 "  placeholder="123-456-7890" required />
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 "> Email </label>
                  <input type="email" id="email" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 " placeholder="name@arimo.in" required />
                </div>

                <div>
                  <label htmlFor="your_city" className="mb-2 block text-sm font-medium text-gray-900 "> City* </label>
                  <input type="text" id="your_city" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 " placeholder="Pune" required />
                </div>

                <div className='sm:col-span-2'>
                  <label htmlFor="vat_number" className="mb-2 block text-sm font-medium text-gray-900 "> Address* </label>
                  <input type="text" id="vat_number" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 " placeholder="Enter full address" required />
                </div>

                <div className="sm:col-span-2">
                  <button type="submit" className="flex w-full items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 ">
                    <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                    </svg>
                    Add new address
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
              <p className="text-xl font-semibold text-gray-900 ">Order summary</p>

              <div className="space-y-4">
                <div className="space-y-2">
                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">Original price</dt>
                    <dd className="text-base font-medium text-gray-900 ">$7,592.00</dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">Savings</dt>
                    <dd className="text-base font-medium text-green-600">-$299.00</dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">Store Pickup</dt>
                    <dd className="text-base font-medium text-gray-900 ">$99</dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">Tax</dt>
                    <dd className="text-base font-medium text-gray-900 ">$799</dd>
                  </dl>
                </div>

                <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                  <dt className="text-base font-bold text-gray-900 ">Total</dt>
                  <dd className="text-base font-bold text-gray-900 ">$8,191.00</dd>
                </dl>
              </div>

              <button onClick={handlePayNow} className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-background bg-orange-500 hover:bg-orange-600  focus:outline-none focus:ring-4 focus:ring-primary-300 ">PAY NOW</button>
          
              {/* <div className="flex items-center justify-center gap-2">
                <span className="text-sm font-normal text-gray-500 "> or </span>
                <Link to="/" title="" className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline">
                  Continue Shopping
                  <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                  </svg>
                </Link>
              </div> */}
            </div>

            <div className="space-y-3">
              <button type="submit" className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 ">Proceed to Payment</button>

              <p className="text-sm font-normal text-gray-500 ">One or more items in your cart require an account. <Link to="/" title="" className="font-medium text-primary-700 underline hover:no-underline">Sign in or create an account now.</Link>.</p>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default Checkout