import React from 'react'
import p1 from '../../assets/images/p1.avif'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
<section>
    <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
        <div className="order-2 lg:order-1 flex flex-col justify-center items-center">
            <p className="text-4xl font-normal md:text-7xl text-orange-600">25% OFF</p>
            <p className="text-4xl font-normal md:text-7xl">WINTER SALE</p>
            <p className="mt-2 text-sm md:text-lg">For limited time only!</p>
            <Link to="/products" className="text-lg md:text-2xl rounded-lg bg-foreground text-background py-2 px-5 mt-10 hover:bg-zinc-800">Shop Now</Link>
        </div>
        <div className="order-1 lg:order-2">
            <img className="h-80 w-80 object-cover lg:w-[500px] lg:h-[500px]" src='https://s.alicdn.com/@sc04/kf/H81a2d2356d044a0d803c141bb0941d5bG.jpg?avif=close' alt=""/>
        </div>
    </div>
</section>
  )
}

export default Hero