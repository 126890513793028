import React from "react";
import ProductItem from "./ProductItem";
import { Product } from "../../types/Product";


const ProductList: React.FC<{ products: Product[]; userId: string }> = ({
  products,
  userId,
}) => {
  return ( 
    <div className="md:px-24 lg:px-24 px-2">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {products.map((product) => (
            <ProductItem product={product}/>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
