import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { Product } from '../../types/Product';
import { db } from '../../services/firebase';
import { Link } from 'react-router-dom';

const AddProductForm: React.FC = () => {
  const [product, setProduct] = useState<Partial<Product>>({
    id: '',
    name: '',
    slug: '',
    description: '',
    category: [],
    pricing: {
      price: 0,
      discountPrice: 0,
      currency: 'USD',
    },
    inventory: {
      stock: 0,
      availability: true,
      sku: '',
    },
    media: {
      images: [],
    },
    meta: {
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProduct((prev: Partial<Product>) => ({ ...prev, [name]: value }));
  };

  const handleNestedInputChange = <K extends keyof Product>(
    section: K,
    key: keyof Product[K],
    value: any
  ) => {
    setProduct((prev) => ({
      ...prev,
      [section]: {
        ...(prev[section] as any),
        [key]: value,
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const productWithId = {
        ...product,
        id: product.id || new Date().getTime().toString(), // Generate ID if not provided
      } as Product;
      await addDoc(collection(db, 'products'), productWithId);
      alert('Product added successfully!');
      setProduct({
        id: '',
        name: '',
        slug: '',
        description: '',
        category: [],
        pricing: {
          price: 0,
          discountPrice: 0,
          currency: 'USD',
        },
        inventory: {
          stock: 0,
          availability: true,
          sku: '',
        },
        media: {
          images: [],
        },
        meta: {
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      });
    } catch (error) {
      console.error('Error adding product: ', error);
      alert('Error adding product.');
    }
  };

  return (
    <main>
        
    <button>
        <Link to="/upload-data">Upload Data</Link>
    </button>
    <form onSubmit={handleSubmit} className="space-y-4 p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold">Add Product</h2>

      <input
        type="text"
        name="name"
        value={product.name || ''}
        onChange={handleInputChange}
        placeholder="Product Name"
        className="w-full p-2 border rounded"
      />

      <input
        type="text"
        name="slug"
        value={product.slug || ''}
        onChange={handleInputChange}
        placeholder="Slug"
        className="w-full p-2 border rounded"
      />

      <textarea
        name="description"
        value={product.description || ''}
        onChange={handleInputChange}
        placeholder="Description"
        className="w-full p-2 border rounded"
      ></textarea>

      <input
        type="number"
        data-section="pricing"
        data-key="price"
        value={product.pricing?.price || ''}
        onChange={(e) => {
          const section = e.currentTarget.dataset.section as keyof Product;
          const key = e.currentTarget.dataset.key;
          handleNestedInputChange(section, key as keyof Product[typeof section], parseFloat(e.target.value));
        }}
        placeholder="Price"
        className="w-full p-2 border rounded"
      />

      <input
        type="number"
        data-section="pricing"
        data-key="discountPrice"
        value={product.pricing?.discountPrice || ''}
        onChange={(e) => {
          const section = e.currentTarget.dataset.section as keyof Product;
          const key = e.currentTarget.dataset.key;
          handleNestedInputChange(section, key as keyof Product[typeof section], parseFloat(e.target.value));
        }}
        placeholder="Discount Price"
        className="w-full p-2 border rounded"
      />

      <input
        type="number"
        data-section="inventory"
        data-key="stock"
        value={product.inventory?.stock || ''}
        onChange={(e) => {
          const section = e.currentTarget.dataset.section as keyof Product;
          const key = e.currentTarget.dataset.key;
          handleNestedInputChange(section, key as keyof Product[typeof section], parseInt(e.target.value));
        }}
        placeholder="Stock"
        className="w-full p-2 border rounded"
      />

      <input
        type="text"
        data-section="inventory"
        data-key="sku"
        value={product.inventory?.sku || ''}
        onChange={(e) => {
          const section = e.currentTarget.dataset.section as keyof Product;
          const key = e.currentTarget.dataset.key;
          handleNestedInputChange(section, key as keyof Product[typeof section], e.target.value);
        }}
        placeholder="SKU"
        className="w-full p-2 border rounded"
      />

      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Add Product
      </button>
    </form>
    </main>
  );
};

export default AddProductForm;