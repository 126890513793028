import React from 'react';
import bannerImage from '../../assets/images/banner/banner.png'

const Banner: React.FC = () => {
    return (
        <div className=''>
          <img 
            src={bannerImage}
            alt="Banner" 
            className='w-3/4 mx-auto'
          />
        </div>
      );
    };

export default Banner;
