import React, { useEffect } from 'react';
import Banner from '../components/banner/Banner';
import Products from '../components/products/Products';

const ProductsPage: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Banner />
      <Products />
    </div>
  );
};

export default ProductsPage;
