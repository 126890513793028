import React, { useState, useEffect, useRef } from "react";
import { CiShoppingCart, CiUser, CiBellOn, CiLogout, CiBoxes } from "react-icons/ci";
import { useAuth } from "../../context/AuthContext";
import { useModal } from "../../context/ModalContext";
import { signOut } from "firebase/auth";
import { auth } from "../../services/firebase";
import { Link } from "react-router-dom";
import getOrCreateUserId from "../../utils/generateUserId";
import { clearCart, clearLocalCart, listenToCartCount, listenToUniqueCartItemCount } from "../../services/cartService";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { openModal } = useModal();
  const { user } = useAuth();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [cartCount, setCartCount] = useState<number>(0);
  const userId = user ? user.uid : getOrCreateUserId();
  const [uniqueItemCounts, setUniqueItemCounts] = useState<{ [key: string]: number }>({});
  const [totalUniqueItems, setTotalUniqueItems] = useState<number>(0);



  useEffect(() => {
    console.log("Setting up cart listener...");
    if (userId) {
      const unsubscribe = listenToCartCount(userId, (count) => {
        
        console.log("Cart count updated:", count);
        setCartCount(count);
        console.log('count after serCartCount()', count)
      });
      return () => {
        console.log("Cleaning up cart listener...");
        unsubscribe(); // Ensure this is called only once
      };
    }
  }, [user]); // Depend only on `user` to set up listener once per session


  // useEffect(() => {
  //   if (user && user.uid) {
  //     const unsubscribe = listenToUniqueCartItemCount(user.uid, (counts) => {
  //       setUniqueItemCounts(counts);

  //       // Calculate the total number of unique items in the cart
  //       const totalCount = Object.values(counts).reduce((acc, count) => acc + count, 0);
  //       setTotalUniqueItems(totalCount);
  //       console.log('total unique item: ', totalUniqueItems)
  //     });

  //     return () => {
  //       unsubscribe();
  //     };
  //   }
  // }, [user]);

  const handleUserIconClick = () => {
    if (!user) {
      openModal();
    } else {
      setIsDropdownOpen((prev) => !prev);
    }
  };

  const handleBlink = ()=>{
    
  }

  const handleSignOut = async () => {
    try {
      clearLocalCart();
      await signOut(auth);
      setIsDropdownOpen(false);
      
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav  className="bg-white  fixed top-0 left-0 w-full py-3 mx-auto max-w-8xl lg:px-24 z-50">
      <div className="mx-auto flex items-center justify-between px-4 py-3">
        <Link to="/">
          <div className="text-lg font-normal text-primary md:hidden">
            <span className="poiret-one-regular text-3xl">arimo.in</span>
          </div>
        </Link>
        <Link to="/">
          <div className="hidden pl-28 md:flex md:flex-grow md:justify-center py-1">
            <span className="poiret-one-regular text-6xl">arimo.in</span>
          </div>
        </Link>

        <div className="flex items-center space-x-4" ref={dropdownRef}>
          {/* Notification Icon */}
          <div className="relative">
            <button className="color-primary hover:text-amber-700">
              <CiBellOn size={28} />
            </button>
            <span
              className="bg-amber-600 text-[12px] px-[6px] font-semibold min-w-[16px] h-4 flex items-center justify-center text-background rounded-full absolute -top-1 left-[60%]">1</span>
          </div>

          {/* Cart Icon with Count */}
          <Link to="/cart">
            <div className="relative">
              {cartCount > 0 && (
                 <span
                 className="bg-amber-600 text-[12px] px-[6px] font-semibold min-w-[16px] h-4 flex items-center justify-center text-background rounded-full absolute -top-1 left-[60%]">{cartCount}</span>
              )}
              <button className="color-primary hover:text-amber-700">
                <CiShoppingCart size={28} />
              </button>
            
            </div>
          </Link>

          {/* User Icon */}
          <div className="relative">
          <button
            onMouseEnter={() => {
              if (user) setIsDropdownOpen(true);
            }}
            onClick={handleUserIconClick}
            className="color-primary hover:text-amber-700"
          >
            <CiUser size={28} />
          </button>

          {isDropdownOpen && (
            <div
              onMouseLeave={() => setIsDropdownOpen(false)}
              className="absolute right-0 top-2 mt-8 w-52 bg-background border border-stone-300 shadow-lg rounded-lg z-50"
            >
              <div className="rounded-lg bg-base-300 p-3 drop-shadow-xl divide-y divide-neutral">
                <div className="flex space-x-4 items-center pb-2">
                  <div className="flex mr-auto items-center space-x-4">
                    <img
                      src={user?.photoURL || "https://avatars.githubusercontent.com/u/26052038?v=4"}
                      alt={user?.displayName || "Name"}
                      className="w-12 h-12 shrink-0 rounded-full"
                    />
                    <div className="space-y-2 flex flex-col flex-1 truncate">
                      <div className="relative leading-tight text-secondary">
                        <span className="flex">
                          <span className="truncate relative pr-8 text-accent">
                            {user?.displayName || "Name"}
                          </span>
                        </span>
                      </div>
                      <p className="font-normal text-secondary text-base leading-tight truncate">
                        {user?.email || "example@mail.com"}
                      </p>
                    </div>
                  </div>
                </div>
                <div aria-label="navigation" className="py-2">
                  <nav className="grid gap-1">
                    <a
                      href="/profile"
                      className="flex items-center leading-6 space-x-3 py-2 px-4 w-full text-lg text-secondary focus:outline-none hover:bg-stone-300 rounded-md"
                    >
                      <CiUser size={24} />
                      <span>Profile</span>
                    </a>
                    <a
                      href="/"
                      className="flex items-center leading-6 space-x-3 py-2 px-4 w-full text-lg text-secondary focus:outline-none hover:bg-stone-300 rounded-md"
                    >
                      <CiBoxes size={24} />
                      <span>Orders</span>
                    </a>
                  </nav>
                </div>
                <div className="pt-2">
                  <button
                    onClick={handleSignOut}
                    className="flex items-center space-x-3 py-2 px-4 w-full leading-6 text-lg text-secondary focus:outline-none hover:bg-stone-300"
                  >
                    <CiLogout size={24} />
                    <span>Logout</span>
                  </button>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
