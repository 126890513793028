import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { CartItem } from "../types/CartItem";
import { db } from "./firebase";
import { Product } from "../types/Product";

export const LOCAL_STORAGE_CART_KEY = "user_cart";

// --- Local Storage Utilities ---

export const getLocalCart = (): CartItem[] => {
  const cart = localStorage.getItem(LOCAL_STORAGE_CART_KEY);
  return cart ? JSON.parse(cart) : [];
};

export const setLocalCart = (cart: CartItem[]) => {
  localStorage.setItem(LOCAL_STORAGE_CART_KEY, JSON.stringify(cart));
};

export const clearLocalCart = () => {
  localStorage.removeItem(LOCAL_STORAGE_CART_KEY);
};

// --- Firestore Cart Services ---

export const syncCartWithFirestore = async (userId: string) => {
    try {
      console.log("Starting cart sync for user:", userId);
  
      const cartDoc = doc(db, "carts", userId);
      const cartSnap = await getDoc(cartDoc);
  
      console.log("Cart snapshot fetched from Firestore:", cartSnap.exists() ? "exists" : "does not exist");
  
      const firestoreCart = cartSnap.exists() ? cartSnap.data().items || [] : [];
      console.log("Firestore cart data:", firestoreCart);
  
      const localCart = getLocalCart();
      console.log("Local cart data:", localCart);
  
      // Merge carts and update the local storage
      const mergedCart = mergeCarts(firestoreCart, localCart);
      console.log("Merged cart data:", mergedCart);
  
      const localCartString = JSON.stringify(localCart);
      const mergedCartString = JSON.stringify(mergedCart);
  
      console.log("Local cart JSON string:", localCartString);
      console.log("Merged cart JSON string:", mergedCartString);
  
      // Update local storage only if there's a change
      if (localCartString !== mergedCartString) {
        console.log("Local cart has changed. Updating local storage.");
        setLocalCart(mergedCart);
      } else {
        console.log("Local cart has not changed. No update to local storage.");
      }
  
      // Only update Firestore if needed
      if (!cartSnap.exists() || mergedCartString !== JSON.stringify(firestoreCart)) {
        console.log("Firestore cart is different from the merged cart. Updating Firestore...");
        await setDoc(cartDoc, { items: mergedCart });
        console.log("Firestore cart updated successfully.");
      } else {
        console.log("Firestore cart is already up-to-date. No update needed.");
      }
  
      return mergedCart;
    } catch (error) {
      console.error("Error syncing cart:", error);
      return [];
    }
  };
  
  function mergeCarts(firestoreCart: CartItem[], localCart: CartItem[]): CartItem[] {
    console.log("Starting merge process...");
    console.log("Firestore cart data:", firestoreCart);
    console.log("Local cart data:", localCart);
  
    const mergedCart: CartItem[] = [];
  
    // Add all items from Firestore, use quantities from Firestore
    for (const item of firestoreCart) {
      const existingItemInLocal = localCart.find((localItem) => localItem.id === item.id);
      if (existingItemInLocal) {
        // Log that this item already exists in the local cart and is kept from Firestore
        console.log(`Item with ID ${item.id} found in both carts. Keeping Firestore quantity: ${item.quantity}`);
        mergedCart.push(item);
      } else {
        // Log the item is added as it is only in Firestore
        console.log(`Item with ID ${item.id} only found in Firestore. Adding to merged cart.`);
        mergedCart.push(item);
      }
    }
  
    // Add items from localCart that are not in firestoreCart (keep local quantities)
    for (const item of localCart) {
      if (!mergedCart.some((mergedItem) => mergedItem.id === item.id)) {
        console.log(`Item with ID ${item.id} only found in local cart. Adding to merged cart.`);
        mergedCart.push(item);
      }
    }
  
    console.log("Merged cart after processing:", mergedCart);
    return mergedCart;
  }
  
  

export const addItemToCart = async (userId: string, item: CartItem) => {
  const localCart = getLocalCart();
  const existingItem = localCart.find(
    (cartItem) =>
      cartItem.productId === item.productId &&
      cartItem.size === item.size &&
      cartItem.color === item.color
  );

  if (existingItem) {
    existingItem.quantity += item.quantity;
  } else {
    localCart.push(item);
  }

  setLocalCart(localCart);
  debounceFirestoreUpdate(userId, localCart);
};

export const updateCartItemQuantity = async (userId: string, itemId: string, quantity: number) => {
  const localCart = getLocalCart();
  const item = localCart.find((cartItem) => cartItem.id === itemId);

  if (item) {
    item.quantity = quantity;
    setLocalCart(localCart);
    debounceFirestoreUpdate(userId, localCart);
  }
};

export const removeCartItem = async (userId: string, itemId: string) => {
  const localCart = getLocalCart();
  const updatedCart = localCart.filter((cartItem) => cartItem.id !== itemId);

  setLocalCart(updatedCart);
  debounceFirestoreUpdate(userId, updatedCart);
};

export const clearCart = async (userId: string) => {
  setLocalCart([]);
  const cartDoc = doc(db, "carts", userId);
  await setDoc(cartDoc, { items: [] });
};

// --- Real-time Cart Count Listener ---

export const listenToCartCount = (userId: string, callback: (count: number) => void) => {
    console.log("Setting up Firestore listener for cart...");
    const cartDoc = doc(db, "carts", userId);
  
    const unsubscribe = onSnapshot(cartDoc, (snapshot) => {
      if (snapshot.exists()) {
        const items = snapshot.data().items as CartItem[];
        const totalCount = items.reduce((acc, item) => acc + item.quantity, 0);
        console.log("Snapshot received. Total count:", totalCount);
        callback(totalCount);
      } else {
        console.log("Snapshot empty. Setting count to 0.");
        callback(0);
      }
    });
  
    return unsubscribe;
  };

  export const listenToUniqueCartItemCount = (
    userId: string,
    callback: (uniqueItemCounts: { [key: string]: number }) => void
  ) => {
    console.log("Setting up Firestore listener for unique cart item count...");
    const cartDoc = doc(db, "carts", userId);
  
    const unsubscribe = onSnapshot(cartDoc, (snapshot) => {
      if (snapshot.exists()) {
        const items = snapshot.data()?.items as CartItem[];
        if (items) {
          // Count unique items by productId, size, and color
          const uniqueItemCounts: { [key: string]: number } = {};
  
          items.forEach((item) => {
            const key = `${item.productId}-${item.size}-${item.color}`;
            if (uniqueItemCounts[key]) {
              uniqueItemCounts[key] += item.quantity;
            } else {
              uniqueItemCounts[key] = item.quantity;
            }
          });
  
          console.log("Unique item counts:", uniqueItemCounts);
          callback(uniqueItemCounts);
        }
      } else {
        console.log("Snapshot empty. No items in the cart.");
        callback({});
      }
    });
  
    return unsubscribe;
  };
// --- Debounced Firestore Update ---

let updateTimeout: NodeJS.Timeout | null = null;

export const debounceFirestoreUpdate = (userId: string, cart: CartItem[]) => {
  if (updateTimeout) clearTimeout(updateTimeout);

  updateTimeout = setTimeout(async () => {
    const cartDoc = doc(db, "carts", userId);
    await setDoc(cartDoc, { items: cart });
  }, 500); // Update after 500ms of inactivity
};


export const convertProductToCartItem = (
  product: Product,
  size?: string,
  color?: string,
  quantity: number = 1
): CartItem => {
  return {
    id: uuidv4(), // Unique ID for the cart item
    productId: product.id, // Link to the product ID
    name: product.name, // Product name for display
    price: product.pricing.discountPrice || product.pricing.price, // Price with discount if applicable
    quantity, // Number of units
    currency: product.pricing.currency, // Currency code or symbol
    size: size || "default", // Size of the product
    color: color || "default", // Color of the product
    image: product.media.images[0] || "", // Main image of the product
  };
};


export const migrateCartData = async (oldUserId: string, newUserId: string) => {
    try {
      const oldCart = getLocalCart(); // Retrieve local cart data using the old user ID
  
      // Save the cart data to Firestore under the new user ID
      const cartDoc = doc(db, "carts", newUserId);
      await setDoc(cartDoc, { items: oldCart });
  
      // Clear the local cart
      setLocalCart([]);
      console.log("Cart data migrated successfully.");
    } catch (error) {
      console.error("Error migrating cart data:", error);
    }
  };
  

  /**
 * Fetches the total price of items in the cart for a given user.
 * @param userId - The user's ID whose cart total price is to be calculated.
 * @returns {Promise<number>} - The total price of the cart items.
 */
export const getTotalPrice = async (userId: string): Promise<number> => {
    try {
      // Reference the user's cart document in Firestore
      const cartDocRef = doc(db, "carts", userId);
      const cartDoc = await getDoc(cartDocRef);
  
      if (!cartDoc.exists()) {
        console.warn("No cart found for the user.");
        return 0;
      }
  
      const cartData = cartDoc.data();
      const items = cartData.items as CartItem[];
  
      // Calculate the total price
      const totalPrice = items.reduce((total, item) => {
        const itemTotal = (item.price || 0) * (item.quantity || 0);
        return total + itemTotal;
      }, 0);
  
      return totalPrice;
    } catch (error) {
      console.error("Error fetching total cart price:", error);
      throw error;
    }
  };