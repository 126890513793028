import React from 'react'
import Hero from '../components/hero/Hero'
import Products from '../components/products/Products'
import { useAuth } from '../context/AuthContext'



const Home = () => {

const { user } = useAuth();

const userId = user?.uid;

if(userId){
}
  return ( 
    <main>
      <Hero />
      <Products />
    </main>
  )
}

export default Home